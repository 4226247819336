<template>
  <div class="compare-box">
    <div class="top">
      <span class="back-save" @click="handleSubmitBack" v-if="ocrStatus != 2">保存并返回</span>
      <span v-else>
        <img class="back" src="@/assets/file-read.png"  @click="handleBack"/>
      </span>
      
      <span @click="handleToggleMenu">
        <img src="@/assets/icon-menu.png" />
        {{menuFLag ? '收起' : '展开'}}缩略图
      </span>
    </div>
    <div class="compare-img-box">
      <div class="img-box" v-if="menuFLag">
        <div
          class="img-box-cont"
          :class="['img-box-cont', {'imgActive': item.ocrImgId == checkedImg}]"
          v-for="item in bookImgData"
          :key="item.id"
        >
          <img
            :src="`${imgPath}${item.compressFilePath}`"
            @click="handleChangeImg(item.ocrImgId)"
            :title="item.fileName"
          />
        </div>
      </div>
      <div class="page-box">
        <div class="pagination">
          <a-pagination
            v-model="pageNum"
            :total="bookImgData.length"
            :page-size="1"
            show-quick-jumper
            @change="handleChange"
          >
            <template slot="buildOptionText" slot-scope="props">
              <span v-if="props.value !== '50'">{{ props.value }}条/页</span>
            </template>
          </a-pagination>
        </div>
        <div class="page-box-right">
          <span class="time" v-if="bookImgData.length && bookImgData[pageNum - 1].sysEditTime">上次修改时间是：{{bookImgData[pageNum - 1].sysEditTime}}</span>
          <a :href="toolPath" target="_blank">查字工具</a>
          <span :class="['button-primary', {'button-disabled': ocrStatus == 2}]" @click="handleSubmit">保存</span>
          <span :class="['button-search', {'button-disabled': ocrStatus == 2}]" @click="handleSubmitNext">{{pageNum == bookImgData.length ? '进入文本处理' : '保存并下一页'}}</span>
        </div>
      </div>
    </div>
    <iframe v-if="!iframeErrorText" :src="path" :class="['compare-cont', {noOpen: !menuFLag}]" id="show-iframe" ref="iframe"></iframe>
    <div class="compare-cont" style="padding-top: 100px" v-else>{{ iframeErrorText }}</div>
  </div>
</template>

<script>
import { getOcrCheckList, completePage } from '@/api/ocr'
import Cookies from 'js-cookie';
  
export default {
  data() {
    return {
      path: '',
      bookId: '',
      imgPath: process.env.VUE_APP_IMG_PATH,
      bookImgData: [],
      pageNum: 1,
      pageSize: 1000,
      menuFLag: true,
      checkedImg: '',
      backLoading: false,
      toolPath: process.env.VUE_APP_TOOL_URL + '&userKey=' + JSON.parse(Cookies.get('userInfo')).chaKey,
      ocrStatus: 0,
      iframeLoading: false,
      timer: null,
      iframeErrorText: '',
      time: null
    }
  },
  async mounted() {
    const { imgId, bookId, path, ocrStatus} = this.$route.query
    this.bookId = bookId
    this.checkedImg = imgId
    this.ocrStatus = ocrStatus

    this.getImgList('one')

    window.addEventListener('message', (event) => {
      if (event.data == 0) {
      } else if (event.data == 1) {
        this.getImgList()
        if (this.backLoading) {
          this.$router.replace(`/ocrImg?id=${JSON.parse(path).id}&bookName=${JSON.parse(path).bookName}&ocrStatus=${JSON.parse(path).ocrStatus}`)
          this.backLoading = false
        }
      }
    });

    this.getIframeLoading()
  },
  methods: {
    // 监听iframe加载
    getIframeLoading() {
      this.iframeLoading = false
      let iframe = document.getElementById("show-iframe");

      if (iframe.attachEvent) {
        iframe.attachEvent("onload", () => {
          this.iframeLoading = true
        });
      } else {
        iframe.onload = () => {
          this.iframeLoading = true
        };
      }

      this.time = setTimeout(() => {
        if (!this.iframeLoading) {
          this.iframeErrorText = '当前网络不稳定，请稍候尝试'
        }
        clearTimeout(this.time)
        
      }, 120000)
    },
    // 保存
    handleSubmit() {
      // 禁止状态
      if (this.ocrStatus == 2) return
      let iframe = document.getElementById("show-iframe");
      if(iframe){
        iframe.contentWindow.postMessage({
          type: 'saveContent'
        }, '*');
      }
    },
    // 返回上一页
    handleBack() {
      const { path} = this.$route.query
      this.$router.replace(`/ocrImg?id=${JSON.parse(path).id}&bookName=${JSON.parse(path).bookName}&ocrStatus=${JSON.parse(path).ocrStatus}`)
    },
    // 保存并下一页
    handleSubmitNext() {
      if (this.ocrStatus == 2) return
      this.handleSubmit()

      let _index = this.bookImgData.findIndex(item => item.ocrImgId == this.checkedImg) + 1
      if (_index == this.bookImgData.length) {
        this.handleBeforeTask()
      } else {
        this.pageNum = _index + 1
        this.checkedImg = this.bookImgData[_index].ocrImgId
        let _id = this.bookImgData[_index].id
        setTimeout(() => {
          this.path = `${process.env.VUE_APP_IMGCOMPARE_URL}?imgId=${this.checkedImg}&id=${_id}&view=${this.bookImgData[_index].view}`
        }, 1000)
      }
    },
    // 保存并返回
    handleSubmitBack() {
      this.backLoading = true
      this.handleSubmit()
    },
    // 目录展开收起
    handleToggleMenu() {
      this.menuFLag = !this.menuFLag
    },
    // 图片切换
    handleChangeImg(imgId) {
      this.checkedImg = imgId
      let _index = this.bookImgData.findIndex(item => item.ocrImgId == this.checkedImg)
      this.pageNum = _index + 1
      this.path = `${process.env.VUE_APP_IMGCOMPARE_URL}?imgId=${imgId}&id=${this.bookImgData[_index].id}&view=${this.bookImgData[_index].view}`

      // 缩略图滚动到可见区域
      this.timer = setTimeout(() => {
        document.getElementsByClassName('img-box')[0].scrollLeft  = document.getElementsByClassName('imgActive')[0].offsetLeft
        clearTimeout(this.timer)
      }, 500)
    },
    // 页码切换
    handleChange(current) {
      this.handleChangeImg(this.bookImgData[current - 1].ocrImgId)
    },
    // 获取列表
    async getImgList(type) {
      const res = await getOcrCheckList({
        sysBookId: this.$route.query.bookId,
      })
      this.bookImgData = res.data
      if (type == 'one') {
        this.handleChangeImg(this.checkedImg)
      }
    },
    // 进入文本前的处理
    async handleBeforeTask() {
      try {
        await completePage({bookId: this.$route.query.bookId})
        this.$router.push('/text')
      } catch (error) {
        console.log('error', error)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.compare-box {
  background: #EDF0F2;
  .top {
    width: 100%;
    height: 46px;
    background: #FFFFFF;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    border: 1px solid #E6E6E6;
    margin: 0 0 4px;
    text-align: left;
    display: flex;
    align-items: center;
    padding: 0 19px;
    .back-save {
      width: 124px;
      height: 34px;
      line-height: 34px;
      background: url('../../assets/icon-submit-back.png') no-repeat;
      background-size: 124px 34px;
      display: inline-block;
      font-size: 14px;
      font-weight: 600;
      color: #FFFFFF;
      padding-left: 40px;
      cursor: pointer;
    }

    .back {
      width: 124px;
      height: 34px;
      cursor: pointer;
    }

    span:nth-of-type(2) {
      margin-left: 26px;
      font-size: 14px;
      font-weight: 400;
      color: #363636;
      line-height: 18px;
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        width: 18px;
        height: 16px;
        margin-right: 10px;
      }
    }
  }

  .compare-img-box {
    .img-box {
      padding: 4px 18px;
      background: #fff;
      margin-top: 4px;
      text-align: left;
      background: #FFFFFF;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      border: 1px solid #E6E6E6;
      overflow-x: scroll;
      white-space: nowrap;
      display: flex;
      .img-box-cont {
        width: 126px;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 7px;
        border: 1px solid #ECECEC;
        cursor: pointer;
        img {
          max-width: 122px;
          max-height: 66px;
        }
      }
    }

    .page-box {
      display: flex;
      justify-content: space-between;
      background-color: #fff;
      // height: 57px;
      height: 46px;
      align-items: center;
      border-top: 1px dashed #C6C4C4;
      border-bottom: 1px dashed #C6C4C4;
      padding: 0 19px;
      .pagination {
        margin-top: 0;
      }

      .page-box-right {
        display: flex;
        align-items: center;
        .time {
          margin-right: 20px;
        }
        a {
          font-style: normal;
          font-size: 14px;
          font-weight: 400;
          color: #6868CE;
          margin-right: 60px;
          cursor: pointer;
        }

        .page-font-size {
          margin-right: 89px;
          span {
            width: 16px;
            height: 16px;
            line-height: 16px;
            text-align: center;
            background: #E5E5E5;
            border-radius: 4px;
            font-size: 9px;
            color: #6868CE;
            margin-left: 6px;
            display: inline-block;
            &.active {
              width: 20px;
              height: 20px;
              line-height: 20px;
              text-align: center;
              background: #6868CE;
              border-radius: 4px;
              font-size: 12px;
              color: #fff;
              font-weight: bold;
            }
          }
        }
      }
    }
  }

  .compare-cont {
    width: 100%;
    height: 75vh;
    background: #fff;
    padding: 0 35px;
    background: #fff;
    border: none;
  }

  .imgActive {
    border: 2px #6868CE solid !important;
  }
}
</style>